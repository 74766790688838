<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <div>
          <p>电梯数量</p>
          <p>{{elevatorInfo.elevatorQuantity}}</p>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <p>维修数量</p>
          <p>{{elevatorInfo.repairQuantity}}</p>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <p>保养数量</p>
          <p>{{elevatorInfo.maintainQuantity}}</p>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <p>物联网设备数量</p>
          <p>{{elevatorInfo.iotDeviceQuantity||0}}</p>
        </div>
      </el-col>
    </el-row>
    <div style="display: flex;flex-direction: row">
      <div id="repairNum" style="width: 600px">
      </div>
      <div style="width: 600px; ">
        <H3 style="margin-bottom: 20px">召修综合统计</H3>
        <div>
          <el-radio-group v-model="repairDays" @input="getRepairDays">
            <el-radio-button label="7">过去7天</el-radio-button>
            <el-radio-button label="30">过去30天</el-radio-button>
            <el-radio-button label="90">过去90天</el-radio-button>
          </el-radio-group>
        </div>
        <div style="background-color: #f5f6f8;text-align: center;height: 40px;line-height: 40px;">共发生{{repairStatisticsTotal.callRepairNum}}次召修</div>
        <div style="display: flex">
          <div class="box">
            <div class="value">{{repairStatisticsTotal.repairCompleteRate||0}}%</div>
            <div class="name">修复率</div>
          </div>
          <div :span="6" class="box">
            <p class="value"> {{repairStatisticsTotal.avgResponseTime||0}}分钟</p>
            <p class="name">平均响应时间</p>
          </div>
          <div :span="6" class="box">
            <p class="value">{{repairStatisticsTotal.avgRepairTime||0}}分钟</p>
            <p class="name">平均维修时间</p>
          </div>
          <div :span="6" class="box">
            <p class="value">{{repairStatisticsTotal.avgTrappedTime||0}}分钟</p>
            <p class="name">平均困人放人时间</p>
          </div>
        </div>
      </div>
    </div>

    <div id="faultAlarmReason" style="width: 600px;height: 300px">
    </div>
    <div id="repairStatistics" style="width: 600px;height: 300px"></div>
    <div id="elevatorOperationStatistics" style="width: 1200px;height: 300px"></div>
  </div>
</template>

<script>
  import * as echarts from "echarts";

  export default {
    name: "RepairElevator",
    data() {
      return{
        elevatorInfo:{
          elevatorQuantity:0,
          iotDeviceQuantity: null,
          maintainQuantity: 0,
          regularInspectionQuantity: null,
          regularMaintenanceQuantity: null,
          repairQuantity: 0,
        },
        repairStatisticsTotal:{
          avgRepairTime: 4,
          avgResponseTime: null,
          avgTrappedTime: 14,
          callRepairNum: 2,
          repairCompleteRate: 100,
        },
        repairDays:"7",
        configData:{},
      };
    },
    mounted() {
      this.getElevatorStatistic();
      this.getRepairTotal();
      this.getFaultAlarm();
      this.getFaultAlarmReason();
      this.getRepairStatistics();
      this.getElevatorOperationStatistics();
    },
    methods: {
      getElevatorStatistic() {
        this.$http.get("property/customer_company/maintenance/statistics").then(res => {
          this.elevatorInfo = res;
          console.log(res, 40);
        });
      },
      getRepairTotal(repairDays = 7) {
        this.$http.get("/data-statistics/repair-statistics/total", {repairDays: repairDays}).then(res => {
          this.repairStatisticsTotal = res;
          console.log(res, 63);
        });
      },
      getRepairDays(e) {
        this.getRepairTotal(e);
        console.log(e, 76);
      },
      //最近90天故障
      getFaultAlarm() {
        this.$http.get("/data-statistics/property/fault-alarm-statistics/total").then(res => {
          const data = res.sort((a, b) => b.faultQuantity - a.faultQuantity).slice(0, 10).sort((a, b) => a.faultQuantity - b.faultQuantity);
          const dataName = data.map(item => item.realEstateName + item.buildingName);
          const dataValue = data.map(item => item.faultQuantity);
          const chart = echarts.init(document.getElementById("repairNum"));
          const option = {
            title: {
              text: "最近90天故障统计",
              left: "center",
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                // Use axis to trigger tooltip
                type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
              },
            },
            grid: {

              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "value",

            },
            yAxis: {
              type: "category",
              data: dataName,
              axisLabel: {interval: 0, rotate: 0},
              // axisTick:{alignWithLabel:true},
            },
            series: [
              {
                data: dataValue,
                type: "bar",
                showBackground: true,
                itemStyle: {
                  borderRadius: 5,
                  borderWidth: 1,
                  color: "#ee5c80",
                },
                label: {
                  show: true,
                  distance: 10,
                },
              },
            ],
          };
          chart.setOption(option);
        });
      },
      getFaultAlarmReason() {
        this.$http.get("/data-statistics/property/fault-alarm-statistics/total-reason").then(res => {
          const chart = echarts.init(document.getElementById("faultAlarmReason"));
          console.log(document.getElementById("faultAlarmReason"));
          const data = [{
                          value:res.personReason,name:"人为因素",

                        },{
                          value:res.deviceReason,name:"设备原因",
                        },
                        {
                          value:res.confirm,name:"未确认",
                        },
          ];
          const option = {
            title: {
              text: "近90天故障原因统计",
              left: "center",
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              orient: "vertical",
              left: "left",
            },
            series: [
              {
                name: "Access From",
                type: "pie",
                radius: "50%",
                data: data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          };
          chart.setOption(option);
        });
      },
      getRepairStatistics() {
        this.$http.get("/data-statistics/property/repair-statistics/total-num").then(res => {
          // this.repairStatistics = res;

          const name = res.map(item => item.repairDate);
          const value = res.map(item => item.repairNum);
          const chart = echarts.init(document.getElementById("repairStatistics"));
          console.log(document.getElementById("repairStatistics"));
          const option = {
            title: {
              text: "近90天召修次数统计",
              left: "center",
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: name,
                axisTick: {
                  alignWithLabel: true,
                },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "召修数量",
                type: "bar",
                barWidth: "60%",
                data: value,
                label: {
                  show: true,
                  // position: 'inside'
                },
              },
            ],
          };
          chart.setOption(option);
        });
      }, // epair-statistics
      getElevatorOperationStatistics() {
        this.$http.get("/data-statistics/property/elevator-operation-statistics/total").then(res => {
          const data = res.sort((a, b) => b.totalRunningCount - a.totalRunningCount).slice(0, 10).sort((a, b) => a.totalRunningCount - b.totalRunningCount);
          const dataName = data.map(item => item.realEstateName + item.buildingName);
          const dataValue = data.map(item => item.totalRunningCount);
          console.log(res,280);
          const chart = echarts.init(document.getElementById("elevatorOperationStatistics"));

          const option = {
            title: {
              text: "近90天直梯运行次数",
              left: "center",
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                // Use axis to trigger tooltip
                type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
              },
            },
            grid: {

              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "value",

            },
            yAxis: {
              type: "category",
              data: dataName,
              axisLabel: {interval: 0, rotate: 0},
              // axisTick:{alignWithLabel:true},
            },
            series: [
              {
                data: dataValue,
                type: "bar",
                showBackground: true,
                itemStyle: {
                  borderRadius: 5,
                  borderWidth: 1,
                  color: "#ee5c80",
                },
                label: {
                  show: true,
                  distance: 10,
                },
              },
            ],
          };
          chart.setOption(option);
          console.log(res,280);
        });
      }, // elevator-operation-statistics
    },

  };
</script>



<style scoped lang="scss">
.box{

  text-align: center;
  border: 1px solid #e6e6e6;
  height: 150px;
  width: 25%;
}
.value{
  font-weight: bolder;
  font-size: 30px;
}
.name{
  margin-top: 20px;
}
</style>
